import { defineStore } from 'pinia'
import type { MotorcycleDatabase } from '@/types/supabaseMotorcycle'
import type { FilterDatabase } from '@/types/supabaseFilter'

type Manufacturers = FilterDatabase['filter']['Tables']['manufacturers']['Row'] | null
type Models = MotorcycleDatabase['motorcycle']['Tables']['vehicleModel']['Row'] | null

export const useSearchMotorcycleStore = defineStore('searchMotorcycleStore', () => {
  /**
   * ! Pinia State !
   *
   * @param selectManufacturer 제조사 정보
   * @param selectModel 모델 정보
   *
   * @param selectOwnerManufacturer 소유자 제조사 정보
   * @param selectOwnerModel 소유자 모델 정보
   *
   * @param selectCoachingManufacturer 코칭 제조사 정보
   * @param selectCoachingModel 코칭 모델 정보
   *
   * @param selectRepairManufacturer 정비 제조사 정보
   * @param selectRepairModel 정비 모델 정보
   *
   * @param selectRescueManufacturer 구난 제조사 정보
   * @param selectRescueModel 구난 모델 정보
   * @param selectPickupManufacturer 탁송 제조사 정보
   * @param selectPickupModel 탁송 모델 정보
   *
   */

  const selectManufacturer = ref<Manufacturers>(null)
  const selectModel = ref<Models>(null)

  const selectOwnerManufacturer = ref<Manufacturers>(null)
  const selectOwnerModel = ref<Models>(null)

  const selectCoachingManufacturer = ref<Manufacturers>(null)
  const selectCoachingModel = ref<Models>(null)

  const selectRepairManufacturer = ref<Manufacturers>(null)
  const selectRepairModel = ref<Models>(null)

  const selectRescueManufacturer = ref<Manufacturers>(null)
  const selectRescueModel = ref<Models>(null)

  const selectPickupManufacturer = ref<Manufacturers>(null)
  const selectPickupModel = ref<Models>(null)

  return {
    selectManufacturer,
    selectModel,
    selectOwnerManufacturer,
    selectOwnerModel,
    selectCoachingManufacturer,
    selectCoachingModel,
    selectRepairManufacturer,
    selectRepairModel,
    selectRescueManufacturer,
    selectRescueModel,
    selectPickupManufacturer,
    selectPickupModel,
  }
}, {
  persist: {
    storage: piniaPluginPersistedstate.sessionStorage(),
  },
})
